
































import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'Banner',
  computed: {
    buttonKey(): string {
      return this.attestation && this.attestation.symptomatic !== null
        ? 'HealthCheckDetails'
        : 'BeginHealthCheck';
    },
    bannerTextKey(): string {
      if (this.attestation && this.attestation.symptomatic !== null) {
        return 'TriageSubtextDone';
      }
      return 'TriageSubtext';
    },
    ...mapState(['attestation', 'config', 'isLoading', 'userProfile']),
  },
});
