



















import Vue from 'vue';
import config from '@/config';

export default Vue.extend({
  name: 'Navigation',
  props: {
    showLinks: Boolean,
  },
  data: () => ({
    logoUrl: config.logoUrl,
  }),
  beforeCreate() {
    window.onbeforeunload = function () {
      // unsets leave alert
    };
  },
  mounted() {
    const checkedPages = ['next-steps', 'testing-centers', 'registration'];
    for (let i = 0; i < checkedPages.length; i++) {
      if (window.location.href.indexOf(checkedPages[i]) > -1) {
        window.onbeforeunload = function () {
          return 'Are you sure you want to leave?';
        };
        const homeLink: HTMLAnchorElement = document.getElementById(
          'homeLink'
        ) as HTMLAnchorElement;
        const iOS =
          navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if (iOS && homeLink) {
          homeLink.addEventListener('click', function (e) {
            e.preventDefault();
            if (window.confirm('Are you sure you want to leave?')) {
              window.location.href = homeLink.href;
            }
          });
        }
      }
    }
  },
  methods: {
    goToAccount: function () {
      this.$router.push('/account');
    },
    goHome: function () {
      this.$router.push('/');
    },
  },
});
