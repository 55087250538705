/**
 * A union type representing either:
 * - A failure with an error type
 * - A success with a value
 */
export type Failable<R, E> =
  | { isError: true; error: E }
  | { isError: false; value: R };

/**
 * Helper class for the implementation of failable
 */
class Failure<E> {
  constructor(public readonly value: E) {}
}
/**
 * An error wrapping implmentation inspired by
 * https://medium.com/@dhruvrajvanshi/making-exceptions-type-safe-in-typescript-c4d200ee78e9
 *
 * It works similarly to new Promise((resolve, reject) => {...}).
 * It takes a function that accepts an object with:
 * - success (a function that returns a successful Failable),
 * - failure (a function that returns a failed Failable)
 * - run (a utility function for running failable functions in succession with error propogation)
 *
 * A basic example:
 * const validate = (): Failable<SuccessType, ErrorType> =>
 *   failable(({ success, failure }) => {
 *     ...
 *     if (validated) {
 *       return success({ data: validValue })
 *     } else {
 *       return failure({ error: ErrorType })
 *     }
 *   });
 *
 * An async example:
 * const fetchSomethingAsync = async (): Failable<SuccessType, ErrorType> =>
 *   failable(async ({ success, failure }) = {
 *     try {
 *       const resource = await fetchSomeRemoteResource();
 *       return success(resource);
 *     } catch(err) {
 *       return failure(err)
 *     }
 *   })
 *
 * One of the benefits of this is that the call signature now forces the caller to handle errors
 * instead of not knowing if the called function could throw.
 *
 * T = never, E = never
 * ensures that E and T are not inferred by the compiler. Without
 * it, if you try to call failable in a context where the return
 * type isn't known,  E and T will be inferred to {} (due to Typescript's
 * lack of global type inference)
 *
 * So, for in the example
 * const a = failable(({ success }) => success(1)
 * Here, a will have type Failable<{}, {}> which is type safe,
 * but not really useful. Ensuring that the developer specifies the type
 * will reduce the confusion/vague type errors.
 */
export function failable<T = never, E = never>(
  f: (arg: {
    success(value: T): Failable<T, E>;
    failure(error: E): Failable<T, E>;
    run<R>(func: () => Failable<R, E>): R;
  }) => Failable<T, E> | Promise<Failable<T, E>>
): Failable<T, E> | Promise<Failable<T, E>> {
  try {
    return f({
      success(value) {
        return {
          isError: false,
          value: value,
        };
      },
      failure(e) {
        return {
          isError: true,
          error: e,
        };
      },
      run(func) {
        const result = func();
        if (result.isError) {
          throw new Failure(result.error);
        } else {
          return result.value;
        }
      },
    });
  } catch (e) {
    if (e instanceof Failure) {
      return {
        isError: true,
        error: e.value,
      };
    } else {
      throw e;
    }
  }
}
