<template>
  <div
    v-if="activeModal === id"
    :id="id"
    class="modal"
    :class="[className, fade ? 'modal__fade' : '']"
  >
    <div class="modal__content">
      <h3 v-if="header" class="modal__header">{{ header }}</h3>
      <p>{{ text }}</p>
      <button v-if="retryFunction" class="button" @click="retryFunction">
        {{ $t('Retry') }}
      </button>
    </div>
    <div class="modal__close text-gray500" @click="clearModal()">
      <i class="fas fa-times-circle"></i>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  props: {
    id: String,
    className: String,
    header: String,
    text: String,
    fade: Boolean,
    retryFunction: Function,
  },
  computed: mapState(['activeModal']),
  mounted() {
    if (this.fade) {
      setTimeout(() => {
        this.clearModal();
      }, 4200);
    }
  },
  methods: {
    clearModal() {
      this.$store.dispatch('setActiveModal', null);
    },
  },
});
</script>
