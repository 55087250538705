import { getEnvString, getEnvBoolean } from '@/helpers/storage';

export interface AppConfig {
  logoUrl: string;
  appName: string;
  analyticsId: string;
  feature: {
    [flag in FeatureFlags]: boolean;
  };
  links: {
    nonDiscriminationNotice?: string;
    consumerInformation?: string;
    accessibility?: string;
    emergency?: string;
    SIS?: string;
    FOIA?: string;
    FAQ?: string;
    privacyPolicy?: string;
    privacy?: string;
    reportAccessibilityBarrier?: string;
  };
  theme: string;
}

// All the potential feature flags within the application
export type FeatureFlags = 'registration' | 'assessment';

const config: AppConfig = {
  logoUrl: getEnvString('VUE_APP_LOGO_URL'),
  appName: getEnvString('VUE_APP_APP_NAME'),
  analyticsId: getEnvString('VUE_APP_GOOGLE_ANALYTICS_ID'),
  feature: {
    registration: getEnvBoolean('VUE_APP_FEATURE_REGISTRATION'),
    assessment: getEnvBoolean('VUE_APP_FEATURE_ASSESSMENT'),
  },
  links: {
    nonDiscriminationNotice: getEnvString(
      'VUE_APP_LINK_NON_DISCRIMINATION_NOTICE'
    ),
    consumerInformation: getEnvString('VUE_APP_LINK_CONSUMER_INFORMATION'),
    accessibility: getEnvString('VUE_APP_LINK_ACCESSIBILITY'),
    emergency: getEnvString('VUE_APP_LINK_EMERGENCY'),
    SIS: getEnvString('VUE_APP_LINK_SIS'),
    FOIA: getEnvString('VUE_APP_LINK_FOIA'),
    FAQ: getEnvString('VUE_APP_LINK_FREQUENTLY_ASKED_QUESTIONS'),
    privacyPolicy: getEnvString('VUE_APP_LINK_PRIVCY_POLICY'),
    privacy: getEnvString('VUE_APP_LINK_PRIVACY'),
    reportAccessibilityBarrier: getEnvString(
      'VUE_APP_LINK_REPORT_ACCESSIBILITY_BARRIER'
    ),
  },
  theme: 'theme-uva',
};

export default Object.freeze(config);
