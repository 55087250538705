import axios, { AxiosResponse } from 'axios';

import {
  AppointmentDate,
  AppointmentTime,
  ConsentResponse,
} from '@/api/models';

import { URLS } from '@/api/urls';
import { Failable, failable } from '@/api/failable';
import { flagEnabled } from '@/api/utils';

/**
 * Get a list of available dates given a lab id.
 * @param {String} id The id of the lab.
 * @returns {AppointmentDate[]} The array of available dates.
 */
export const getLabDaysById = async (
  id: string
): Promise<Failable<AppointmentDate[], Error>> => {
  return failable(async ({ success, failure }) => {
    try {
      if (!flagEnabled('registration')) {
        return success([]);
      }
      const response: AxiosResponse = await axios.get(
        URLS.AVAILABILITY_BY_ID(id)
      );
      const appointmentDate: AppointmentDate[] = response.data;
      return success(appointmentDate);
    } catch (error) {
      return failure(error);
    }
  });
};

/**
 * Get available times for a given day at a given lab.
 * @param {String} id The id of the lab.
 * @param {String} date A MM/DD/YYYY formatted date.
 * @returns {AppointmentTime[]} A list of available times.
 */
export const getLabTimesByDay = async (
  id: string,
  date: string
): Promise<Failable<AppointmentTime[], Error>> => {
  return failable(async ({ success, failure }) => {
    try {
      if (!flagEnabled('registration')) {
        return success([]);
      }
      const response: AxiosResponse = await axios.get(
        URLS.AVAILABILITY_BY_ID_AND_DATE(id, date)
      );
      const appointmentTime: AppointmentTime[] = response.data;
      return success(appointmentTime);
    } catch (error) {
      return failure(error);
    }
  });
};

/**
 * Get the consent agreement for a given lab
 * @param {String} id The id of the lab.
 * @returns {ConsentResponse} The consent agreement.
 */
export const getLabConsentById = async (
  id: string
): Promise<Failable<ConsentResponse, Error>> => {
  return failable(async ({ success, failure }) => {
    if (!flagEnabled('registration')) {
      return success({} as ConsentResponse);
    }
    try {
      const response: AxiosResponse = await axios.get(URLS.CONSENT_BY_ID(id));
      const consent: ConsentResponse = response.data;
      return success(consent);
    } catch (error) {
      return failure(error);
    }
  });
};
