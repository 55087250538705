import axios, { AxiosResponse } from 'axios';

import { buildHeaders, flagEnabled } from '@/api/utils';
import { UserProfileRequest, UserProfileResponse } from '@/api/models';
import { URLS } from '@/api/urls';
import { Failable, failable } from '@/api/failable';

/**
 * Get the user profile associated with an idToken
 * @param {String} idToken The JWT to add to the request
 * @returns {UserProfileResponse | null} The profile or null if it doesn't exist
 */
export const getUserProfile = async (
  idToken: string
): Promise<Failable<UserProfileResponse, Error>> => {
  return failable(async ({ success, failure }) => {
    if (!flagEnabled('registration')) {
      return success({} as UserProfileResponse);
    }
    try {
      const response = await axios.get(URLS.PROFILE, buildHeaders(idToken));
      const profile: UserProfileResponse = response.data;
      return success(profile);
    } catch (err) {
      return failure(err);
    }
  });
};

/**
 * Create a new user profile
 * @param {UserProfileRequest} profile
 * @param {String} idToken The JWT to add to the request
 * @returns {UserProfile} The created profile
 */
export const createUserProfile = async (
  profile: UserProfileRequest,
  idToken: string
): Promise<Failable<UserProfileResponse, Error>> => {
  return failable(async ({ success, failure }) => {
    try {
      const response: AxiosResponse = await axios.post(
        URLS.PROFILE,
        profile,
        buildHeaders(idToken)
      );
      const userProfile: UserProfileResponse = response.data;
      return success(userProfile);
    } catch (err) {
      return failure(err);
    }
  });
};
