import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import config from '@/config';
import HomePage from '../views/HomePage.vue';
import { routeEnabledGuard } from './guards/routeEnabledGuard';
import { getIdToken } from '@/plugins/auth';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    // We need to send users to the home route when they hit index.html
    path: '/index.html',
    redirect: '/',
  },
  {
    path: '/account',
    name: 'AccountPage',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "account" */ '../views/AccountPage.vue'),
  },
  {
    path: '/disclaimer',
    name: 'DisclaimerPage',
    meta: {
      requiresAuth: true,
      guardedBy: config.feature.assessment,
    },
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "disclaimer" */ '../views/DisclaimerPage.vue'
      ),
  },
  {
    path: '/disclosure',
    name: 'DisclosurePage',
    meta: {
      requiresAuth: true,
      guardedBy: config.feature.assessment,
    },
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "disclosure" */ '../views/DisclosurePage.vue'
      ),
  },
  {
    path: '/emergency',
    name: 'EmergencyPage',
    meta: {
      requiresAuth: true,
      guardedBy: config.feature.assessment,
    },
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "emergency" */ '../views/EmergencyPage.vue'),
  },
  {
    path: '/emergency-warning',
    name: 'EmergencyWarningPage',
    meta: {
      requiresAuth: true,
      guardedBy: config.feature.assessment,
    },
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "emergencywarning" */ '../views/EmergencyWarningPage.vue'
      ),
  },
  {
    path: '/triage',
    name: 'TriagePage',
    meta: {
      requiresAuth: true,
      guardedBy: config.feature.assessment,
    },
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "triage" */ '../views/TriagePage.vue'),
  },
  {
    path: '/next-steps',
    name: 'NextStepsPage',
    meta: {
      requiresAuth: true,
      guardedBy: config.feature.assessment,
    },
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "next-steps" */ '../views/NextStepsPage.vue'),
  },
  {
    path: '/registration',
    name: 'RegistrationPage',
    meta: {
      requiresAuth: true,
      guardedBy: config.feature.registration,
    },
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "registration" */ '../views/RegistrationPage.vue'
      ),
  },
  {
    path: '/testing-centers',
    name: 'TestingCentersPage',
    meta: {
      requiresAuth: true,
      guardedBy: config.feature.registration,
    },
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "testing-centers" */ '../views/TestingCentersPage.vue'
      ),
  },
  {
    path: '/testing-centers/:id',
    name: 'TestingCenterPage',
    meta: {
      requiresAuth: true,
      guardedBy: config.feature.registration,
    },
    component: () =>
      import(
        /* webpackChunkName: "testing-centers" */ '../views/TestingCenterPage.vue'
      ),
    children: [
      {
        path: 'consent',
        name: 'TestingCenterConsentPage',
        meta: {
          guardedBy: config.feature.registration,
        },
        // route level code-splitting
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "testing-centers" */ '../views/TestingCenterConsentPage.vue'
          ),
      },
      {
        path: 'day',
        name: 'TestingCenterDayPage',
        meta: {
          guardedBy: config.feature.registration,
        },
        // route level code-splitting
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/TestingCenterDayPage.vue'),
      },
      {
        path: 'time',
        name: 'TestingCenterTimePage',
        meta: {
          guardedBy: config.feature.registration,
        },
        // route level code-splitting
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "testing-centers" */ '../views/TestingCenterTimePage.vue'
          ),
      },
    ],
  },
  {
    path: '/account-created',
    name: 'AccountCreatedPage',
    meta: {
      requiresAuth: true,
      guardedBy: config.feature.registration,
    },
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "account-created" */ '../views/AccountCreatedPage.vue'
      ),
  },
  {
    path: '/attestation',
    name: 'AttestationPage',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "attestation" */ '../views/AttestationPage.vue'
      ),
  },
  {
    path: '/sign-out',
    name: 'SignOutPage',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "signout" */ '../views/SignOutPage.vue'),
  },
  {
    path: '/sign-in',
    name: 'SignInPage',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "signin" */ '../views/SignInPage.vue'),
  },
  {
    path: '/android-redirect',
    name: 'AndroidRedirect',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "androidRedirect" */ '../views/androidRedirect.vue'
      ),
  },
  {
    // This route must be last in the list
    // like the default case in a switch
    path: '*',
    name: '404Page',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "404" */ '../views/404Page.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isSignedIn = (await getIdToken()) !== null;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (to.name === 'SignInPage' && isSignedIn) {
    router.push({ name: 'AccountPage' });
  }

  // Check to see if we are forwarding to a page, if not, then check to see if this page is feature guarded.
  if (requiresAuth && !isSignedIn && to.name !== 'SignInPage') {
    router.push({ name: 'SignInPage' });
  } else {
    routeEnabledGuard(to, from, next);
  }
});

export default router;
