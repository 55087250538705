import { ENGLISH_TRANSLATIONS } from '@/assets/en';

export const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const sexOptions = {
  en: {
    M: 'Male',
    F: 'Female',
    U: 'Prefer not to answer',
  },
  es: {
    M: 'Male',
    F: 'Female',
    U: 'Prefer not to answer',
  },
};

export const raceOptions = {
  en: {
    White: 'White',
    Black: 'Black or African American',
    PacificIslander: 'Native Hawaiian or Other Pacific Islander',
    Asian: 'Asian',
    AmericanIndian: 'American Indian or Alaska Native',
    Hispanic: 'Hispanic or Latino',
    TwoOrMore: 'Two or More Races',
    Other: 'Other',
    NoAnswer: 'Prefer Not to Answer',
  },
  es: {
    White: 'White',
    Black: 'Black or African American',
    PacificIslander: 'Native Hawaiian or Other Pacific Islander',
    Asian: 'Asian',
    AmericanIndian: 'American Indian or Alaska Native',
    Hispanic: 'Hispanic or Latino',
    TwoOrMore: 'Two or More Races',
    Other: 'Other',
    NoAnswer: 'Prefer Not to Answer',
  },
};

export const ethnicityOptions = {
  en: {
    Hispanic: 'Hispanic',
    NonHispanic: 'Non-Hispanic',
    NoAnswer: 'Prefer Not to Answer',
  },
  es: {
    Hispanic: 'Hispanic',
    NonHispanic: 'Non-Hispanic',
    NoAnswer: 'Prefer Not to Answer',
  },
};

export const strings = {
  en: ENGLISH_TRANSLATIONS.text,
};
