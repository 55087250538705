import axios, { AxiosResponse } from 'axios';

import { URLS } from '@/api/urls';
import { Failable, failable } from '@/api/failable';
import { AttestationResponse, CreateAttestationRequest } from '@/api/models';
import { buildHeaders } from '@/api/utils';

export const getAttestation = async (
  idToken: string
): Promise<Failable<AttestationResponse, Error>> => {
  return failable(async ({ success, failure }) => {
    try {
      const response: AxiosResponse = await axios.get(
        URLS.ATTESTATION,
        buildHeaders(idToken)
      );
      const attestation: AttestationResponse = response.data;
      return success(attestation);
    } catch (err) {
      return failure(err);
    }
  });
};

export const createAttestation = async (
  attestation: CreateAttestationRequest,
  idToken: string
): Promise<Failable<AttestationResponse, Error>> => {
  return failable(async ({ success, failure }) => {
    try {
      const response: AxiosResponse = await axios.post(
        URLS.ATTESTATION,
        attestation,
        buildHeaders(idToken)
      );
      const createdAttestation: AttestationResponse = response.data;
      return success(createdAttestation);
    } catch (err) {
      return failure(err);
    }
  });
};
