




















import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'PageFooter',
  data() {
    return {
      links: [
        { configKey: 'nonDiscriminationNotice', i18nKey: 'NonDiscrimination' },
        { configKey: 'consumerInformation', i18nKey: 'ConsumerInformation' },
        { configKey: 'accessibility', i18nKey: 'Accessibility' },
        { configKey: 'emergency', i18nKey: 'Emergency' },
        { configKey: 'SIS', i18nKey: 'SIS' },
        { configKey: 'FOIA', i18nKey: 'FOIA' },
        { configKey: 'privacy', i18nKey: 'PrivacyPolicyLink' },
        {
          configKey: 'reportAccessibilityBarrier',
          i18nKey: 'ReportAccessibility',
        },
      ],
    };
  },
  computed: mapState(['config']),
  methods: {
    isLastLink(index: number): boolean {
      return index === this.links.length - 1;
    },
  },
});
