/*
This is the file that defines our Vuex store. Vuex is a state-management plugin for Vue

The store is composed of four main parts: state, getters, actions, mutations.
For larger projects, the store can also be broken down into namespaced modules.

State:
This is the initial state of the app. It is where you can define variables that you are
interested in tracking for changes, like an app-level version of the component data function

Getters:
This is where you can define values that are derived from parts of the state or other getters
It is analogous to the computed variables for a component.

Actions:
Actions are the functions that are usually made accessible to the components in the app. These
functions are where your Vuex store should make any asynchronous calls such as hitting an API
for data. You can call them from components with `this.$store.dispatch('foo')`.
The first parameter to an action function is a context object which contains the functions
`commit` and `dispatch` which can be used to call mutations and other actions respectively.

Mutations:
Mutations are where the state can be changed. You should not mutate the app state outside of
a mutation function. The point is to organize all of the ways that the state can change into
one place. Mutations are called from actions. The first parameter to a mutation function is
a reference to the state.

 ** For components **
Vuex also offers us mapping functions: mapState, mapGetters, and mapActions.
Each one of these will map the corresponding part of the state into an object so that state and
getters and actions can be made easily accessible to a component with something like:
```
import {mapState, mapGetters, mapActions} from 'vuex'
export default {
	...
	computed: {
		...mapState(['foo']),
		...mapGetters(['fooDerived']),
	},
	methods: mapActions(['fooFunction'])
}
```
*/
import Vue from 'vue';
import Vuex from 'vuex';
import config from '@/config';

import { buildActions } from '@/store/actions';
import getters from '@/store/getters';
import mutations from '@/store/mutations';
import { RootState } from '@/store/models';

import { apiService } from '@/api';
import {
  AppointmentDate,
  AppointmentTime,
  ConsentResponse,
  TestingCenter,
} from '@/api/models';
import { getItem, getItemJson } from '@/helpers/storage';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  state: {
    // The id of a modal to be displayed
    activeModal: getItem('activeModal'),
    // List of available days for the selected testing center
    appointmentDates: Array<AppointmentDate>(),
    // List of available times for the selected day
    appointmentTimes: Array<AppointmentTime>(),
    // Object containing information about the user's attestation
    attestation: undefined,
    // The testing center day the user has selected
    currentAppointmentDate: undefined,
    // The testing center time the user has selected
    currentAppointmentTime: getItemJson(
      'currentAppointmentTime'
    ) as AppointmentTime,
    // The testing center the user has selected
    currentTestingCenter: getItemJson('currentTestingCenter') as TestingCenter,
    // The consent of the testing center the user has agreed to
    currentTestingCenterConsent: getItemJson(
      'currentTestingCenterConsent'
    ) as ConsentResponse,
    // An object holding configurable values like the logo url and feature flags
    config,
    // Object that keeps track of API failures
    errors: {
      consent: false,
      labDays: false,
      labTimes: false,
      testingCenters: false,
      testingPolicy: false,
      userProfile: false,
      attestation: false,
    },
    // Flag to keep track of when API calls are in progress
    isLoading: false,
    // Flag to determine if a user qualifies for testing
    isQualified: undefined,
    // Locale to use for language (only en for now)
    locale: 'en',
    // List of testing centers
    testingCenters: Array<TestingCenter>(),
    // The user's profile
    userProfile: undefined,
  },
  actions: buildActions(apiService),
  getters,
  mutations,
});
