import Amplify from 'aws-amplify';
import { Auth, Hub, Cache } from 'aws-amplify';

import awsconfig from '@/awsconfig';
import awsauth from '@/awsauth';

Amplify.configure(awsconfig);
Auth.configure({ oauth: awsauth, storage: Cache });

declare module 'vue/types/vue' {
  interface Vue {
    $auth: {
      federatedSignIn: (options?: {
        customProvider: string;
        customState: string;
      }) => Promise<void>;
      signOut: () => Promise<void>;
      currentAuthenticatedUser: () => Promise<any>;
      currentSession: () => Promise<any>;
    };
    $amplifyHub: {
      listen: (channel: string, listener: (event: any) => any) => void;
      dispatch: (channel: string, payload: any) => void;
    };
  }
}

export const auth = Auth;

export const getIdToken = async (): Promise<string | null> => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (e) {
    // no logged in user
    return null;
  }
};

export const AuthPlugin = {
  // The install method is all that needs to exist on the plugin object.
  // It takes the global Vue object as well as user-defined options.
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  install(Vue: any): void {
    Vue.auth = Auth;
    Vue.amplifyHub = Hub;
    Object.defineProperties(Vue.prototype, {
      $auth: {
        get() {
          return Auth;
        },
      },
      $amplifyHub: {
        get() {
          return Hub;
        },
      },
    });
  },
};
