










import Vue from 'vue';
import PageFooter from '@/components/PageFooter.vue';
import Navigation from '@/components/Navigation.vue';

import { getIdToken } from '@/plugins/auth';
import { RootState } from '@/store/models';
// We have to import it here because we don't have access
// to the state in beforeCreate
import config from '@/config';

const userHasSelectedAppointment = (store: RootState): boolean | undefined => {
  return (
    store.currentTestingCenter &&
    store.currentAppointmentTime &&
    !!store.currentTestingCenterConsent
  );
};

export default Vue.extend({
  name: 'App',
  components: {
    PageFooter,
    Navigation,
  },
  computed: {
    showAccountLink() {
      return this.$route.name === 'HomePage';
    },
    is404() {
      return this.$route.name === '404Page';
    },
    isSignIn() {
      return this.$route.name === 'SignInPage';
    },
    classes() {
      const classes = [];
      classes.push(config.theme);
      classes.push(this.$route.name);

      return classes.join(' ');
    },
  },
  async beforeCreate() {
    this.$store.dispatch('loadInitialData');

    const handleExternalAuthEvent = async ({ payload }: any) => {
      switch (payload.event) {
        case 'cognitoHostedUI':
          this.$amplifyHub.dispatch('auth', {
            event: 'AuthStateChange',
            message: 'signedin',
            data: payload.data,
          });
          break;
        case 'signIn':
          // eslint-disable-next-line no-case-declarations
          const token = await getIdToken();
          // Once the user signs in, re-fetch the profile
          await this.$store.dispatch('getProfile', token);
          // if no profile and a selected appointment, go to registration
          if (
            !this.$store.state.userProfile &&
            userHasSelectedAppointment(this.$store.state) &&
            config.feature.registration
          ) {
            this.$router.push('/registration');
          }
          break;
        case 'customOAuthState':
          // We are only passing the pageName via customState. In development,
          // I found that any special characters will break the sign in flow.
          this.$router.push({ name: `${payload.data}` });
          break;
      }
    };
    this.$amplifyHub.listen('auth', handleExternalAuthEvent);
  },
});
