import axios, { AxiosResponse } from 'axios';
import { TestingCenter } from '@/api/models';

import { Failable, failable } from '@/api/failable';
import { URLS } from '@/api/urls';
import { flagEnabled } from '@/api/utils';

/**
 * Get the list of all testing centers
 * @returns {TestingCenter[]}
 */
export const getTestingCenters = async (): Promise<
  Failable<TestingCenter[], Error>
> => {
  return failable(async ({ success, failure }) => {
    if (!flagEnabled('registration')) {
      return success([]);
    }
    try {
      const response: AxiosResponse = await axios.get(URLS.TESTING_CENTERS);
      const centers: TestingCenter[] = response.data;
      return success(centers);
    } catch (error) {
      return failure(error);
    }
  });
};
