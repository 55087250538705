import axios, { AxiosResponse } from 'axios';

import { Failable, failable } from '@/api/failable';
import { TestingPolicy } from '@/api/models';
import { URLS } from '@/api/urls';
import { flagEnabled } from '@/api/utils';

/**
 * Get the app testing policy
 * @returns {TestingPolicy}
 */
export const getTestingPolicy = async (): Promise<
  Failable<TestingPolicy, Error>
> => {
  return failable(async ({ success, failure }) => {
    if (!flagEnabled('registration')) {
      return success({} as TestingPolicy);
    }
    try {
      const response: AxiosResponse = await axios.get(URLS.TESTING_POLICY);
      const testingPolicy: TestingPolicy = response.data;
      return success(testingPolicy);
    } catch (error) {
      return failure(error);
    }
  });
};
