/**
 * This file is where all the API interactions are defined and implementations are imported.
 * To add a new API call do the following:
 * 1. Add the function name, call signature, and return type to the ApiService interface
 * 2. Implement that API call in an existing or new folder
 * 3. Import that function and export it in the apiService object
 * 4. Call apiService.newFunction() in a Vuex action
 */
import {
  AppointmentDate,
  AppointmentTime,
  AttestationResponse,
  ConsentResponse,
  TestingCenter,
  TestingPolicy,
  UserProfileRequest,
  UserProfileResponse,
  CreateAttestationRequest,
} from '@/api/models';

import { getAttestation, createAttestation } from '@/api/Attestation';
import {
  getLabDaysById,
  getLabTimesByDay,
  getLabConsentById,
} from '@/api/Labs';
import { getTestingCenters } from '@/api/TestingCenters';
import { createUserProfile, getUserProfile } from '@/api/UserProfile';
import { getTestingPolicy } from '@/api/TestingPolicy';

import { Failable } from '@/api/failable';

/**
 * An interface that defines all interactions with the API.
 * Having it as an interface helps mock for testing and defines
 * expectations for callers.
 */
export interface ApiService {
  getTestingCenters: () => Promise<Failable<TestingCenter[], Error>>;
  getLabConsentById: (id: string) => Promise<Failable<ConsentResponse, Error>>;
  getTestingPolicy: () => Promise<Failable<TestingPolicy, Error>>;
  getUserProfile: (
    idToken: string
  ) => Promise<Failable<UserProfileResponse | null, Error>>;
  createUserProfile: (
    profile: UserProfileRequest,
    idToken: string
  ) => Promise<Failable<UserProfileResponse, Error>>;
  getLabDaysById: (id: string) => Promise<Failable<AppointmentDate[], Error>>;
  getLabTimesByDay: (
    id: string,
    date: string
  ) => Promise<Failable<AppointmentTime[], Error>>;
  getAttestation: (
    idToken: string
  ) => Promise<Failable<AttestationResponse, Error>>;
  createAttestation: (
    attestation: CreateAttestationRequest,
    idToken: string
  ) => Promise<Failable<AttestationResponse, Error>>;
}

/**
 * Implementation of the ApiService interface.
 * All requests against the API happen here and will be called in an action
 */
export const apiService: ApiService = {
  getTestingCenters,
  createUserProfile,
  getUserProfile,
  getTestingPolicy,
  getLabConsentById,
  getLabDaysById,
  getLabTimesByDay,
  getAttestation,
  createAttestation,
};
