import { i18n } from '@/i18n';

import {
  AppointmentDate,
  AppointmentTime,
  ConsentResponse,
  TestingCenter,
} from '@/api/models';
import {
  Actions,
  AppActionContext,
  Attestation,
  CreateAttestationPayload,
  CreateProfilePayload,
} from '@/store/models';
import { setItem, removeItem } from '@/helpers/storage';
import { ApiService } from '@/api';
import { getIdToken } from '@/plugins/auth';

/**
 * Exporting this build function instead of just an object allows
 * us to pass different implementations of the ApiService for the app,
 * testing, or different API schemas.
 * @param {ApiService} apiService An implementation of ApiService to call
 * @returns {Actions} An object of store actions
 */
export const buildActions = (apiService: ApiService): Actions => ({
  /* API Actions */
  // API data fetching actions
  // Wrapping these functions in setIsLoading tells the loading spinner when to appear
  /**
   * This function is called at initial page load.
   * It gets
   * - The testing policy
   * - The list of testing centers
   * - The user profile if it exists
   */
  async loadInitialData({ commit, dispatch }: AppActionContext) {
    commit('setIsLoading', true);
    const idToken = await getIdToken();

    dispatch('getTestingPolicy');

    if (idToken) {
      const profile = await apiService.getUserProfile(idToken);
      commit('setErrors', { userProfile: profile.isError });
      commit('setUserProfile', profile.isError ? undefined : profile.value);
    } else {
      commit('setUserProfile', null);
    }
    dispatch('getAttestation', idToken);
    dispatch('getTestingCenters');

    commit('setIsLoading', false);
  },
  async getTestingCenters({ commit }: AppActionContext) {
    const centersResult = await apiService.getTestingCenters();
    commit('setErrors', { testingCenters: centersResult.isError });
    commit(
      'setTestingCenters',
      centersResult.isError ? Array<TestingCenter>() : centersResult.value
    );
  },
  async getLabConsentById({ commit }: AppActionContext, id: string) {
    commit('setIsLoading', true);
    const consent = await apiService.getLabConsentById(id);
    commit('setErrors', { consent: consent.isError });
    commit('setLabConsent', consent.isError ? undefined : consent.value);
    commit('setIsLoading', false);
  },
  async getProfile({ commit }: AppActionContext, idToken: string) {
    if (idToken) {
      commit('setIsLoading', true);
      const profile = await apiService.getUserProfile(idToken);
      commit('setErrors', { userProfile: profile.isError });
      commit('setUserProfile', profile.isError ? null : profile.value);
      commit('setIsLoading', false);
    } else {
      commit('setUserProfile', null);
    }
  },
  async createAttestation(
    { commit }: AppActionContext,
    { symptomatic, idToken, remote }: CreateAttestationPayload
  ) {
    commit('setIsLoading', true);
    const attestationResponse = await apiService.createAttestation(
      { symptomatic, remote },
      idToken
    );
    commit('setErrors', { attestation: attestationResponse.isError });
    commit(
      'setAttestation',
      attestationResponse.isError ? undefined : attestationResponse.value
    );
    commit('setIsLoading', false);
  },
  async getAttestation({ commit }: AppActionContext, idToken: string) {
    if (idToken) {
      commit('setIsLoading', true);
      const attestationResponse = await apiService.getAttestation(idToken);
      commit('setErrors', { attestation: attestationResponse.isError });
      commit(
        'setAttestation',
        attestationResponse.isError ? undefined : attestationResponse.value
      );
      commit('setIsLoading', false);
    } else {
      commit('setAttestation', undefined);
    }
  },
  async getTestingPolicy({ commit }: AppActionContext) {
    const testingPolicyResult = await apiService.getTestingPolicy();
    await commit('setErrors', { testingPolicy: testingPolicyResult.isError });
    commit(
      'setTestingPolicy',
      testingPolicyResult.isError ? undefined : testingPolicyResult.value
    );
  },
  async createProfile(
    { commit }: AppActionContext,
    {
      profile,
      consentVersion,
      testingCenter,
      time,
      idToken,
    }: CreateProfilePayload
  ) {
    commit('setIsLoading', true);
    const body = {
      ...profile,
      consentVersion,
      testingCenter,
      appointmentTime: time,
    };
    const createdProfile = await apiService.createUserProfile(body, idToken);
    commit('setErrors', { userProfile: createdProfile.isError });
    commit(
      'setUserProfile',
      createdProfile.isError ? null : createdProfile.value
    );
    commit('setIsLoading', false);
  },
  async getLabTimesByDay(
    { commit }: AppActionContext,
    { id, date }: { id: string; date: string }
  ) {
    commit('setIsLoading', true);
    const appointmentTime = await apiService.getLabTimesByDay(id, date);
    commit('setErrors', { labTimes: appointmentTime.isError });
    commit(
      'setAppointmentTimes',
      appointmentTime.isError ? undefined : appointmentTime.value
    );
    commit('setIsLoading', false);
  },
  /* State Actions */
  updateQualification({ commit }: AppActionContext, isQualified: boolean) {
    commit('setQualification', isQualified);
  },
  setIsLoading({ commit }: AppActionContext, isLoading: boolean) {
    commit('setIsLoading', isLoading);
  },
  async getLabDaysById({ commit }: AppActionContext, id: string) {
    commit('setIsLoading', true);
    const appointmentDate = await apiService.getLabDaysById(id);
    commit('setErrors', { labDays: appointmentDate.isError });
    commit(
      'setAppointmentDates',
      appointmentDate.isError ? undefined : appointmentDate.value
    );
    commit('setIsLoading', false);
  },
  setCurrentAppointmentDate(
    { commit }: AppActionContext,
    date: AppointmentDate
  ) {
    commit('setCurrentAppointmentDate', date);
  },
  setCurrentAppointmentTime(
    { commit }: AppActionContext,
    time: AppointmentTime
  ) {
    setItem('currentAppointmentTime', time);
    commit('setCurrentAppointmentTime', time);
  },
  setCurrentTestingCenter({ commit }: AppActionContext, center: TestingCenter) {
    setItem('currentTestingCenter', center);
    commit('setCurrentTestingCenter', center);
  },
  setCurrentConsent({ commit }: AppActionContext, consent: ConsentResponse) {
    setItem('currentTestingCenterConsent', consent);
    commit('setLabConsent', consent);
  },
  clearCurrentTestingCenterInfo({ commit }: AppActionContext) {
    [
      'currentTestingCenter',
      'currentAppointmentTime',
      'currentTestingCenterConsent',
    ].map((item) => removeItem(item));

    commit('setCurrentAppointmentTime', undefined);
    commit('setCurrentAppointmentDate', undefined);
    commit('setCurrentTestingCenter', undefined);
    commit('setLabConsent', undefined);
  },
  setActiveModal({ commit }: AppActionContext, modalId: string | null) {
    if (modalId === null) {
      removeItem('activeModal');
    } else {
      setItem('activeModal', modalId);
    }
    commit('setActiveModal', modalId);
  },
  setLocale({ commit }: AppActionContext, locale: string) {
    i18n.locale = locale;
    setItem('locale', locale, false);
    commit('setLocale', locale);
  },
  setAttestation({ commit }: AppActionContext, attestation: Attestation) {
    commit('setAttestation', attestation);
  },
});
