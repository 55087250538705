import { AxiosRequestConfig } from 'axios';
import config, { FeatureFlags } from '@/config';

/**
 * A helper function that adds a given access token to Authorization headers
 * @param {String} idToken The access token to add to the request headers
 * @returns {AxiosRequestConfig} An object to pass to an axios request
 */
export const buildHeaders = (idToken: string): AxiosRequestConfig => ({
  headers: { Authorization: `Bearer ${idToken}` },
});

/**
 * A helper function that checks if a feature is flag enabled based on the properties
 * provided in the .env file.
 * @param {FeatureFlags} flag The corresponding feature flag in the config object
 * @returns {boolean} A boolean indicating whether the flag is enabled
 */
export const flagEnabled = (flag: FeatureFlags): boolean => {
  switch (flag) {
    case 'registration':
      return config.feature.registration;
    default:
      return true;
  }
};
