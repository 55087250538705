export default {
  Auth: {
    region: 'us-east-1',
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID,
    mandatorySignIn: false,
    redirectSignIn: process.env.VUE_APP_REDIRECT_URL,
    redirectSignOut: process.env.VUE_APP_REDIRECT_URL,
  },
};
