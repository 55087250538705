



































import Vue from 'vue';

export default Vue.extend({
  props: {
    src: String,
    alt: String,
    cardTitle: String,
    cardText: String,
    cardSubtext: String,
    buttonText: String,
    link: String,
    buttonDataTest: String,
    external: Boolean,
  },
});
