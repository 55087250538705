import { RootState } from '@/store/models';
import {
  TestingCenter,
  TestingCentersByCity,
} from '@/api/models/TestingCenter';

type SupportedLanguage = 'en' | 'es';

export default {
  testingCentersByCity(state: RootState): TestingCentersByCity {
    const { testingCenters } = state;

    // Returns an object with the city as the key
    // and an array of TestingCenters as the value
    return testingCenters.reduce(
      (acc: TestingCentersByCity, tc: TestingCenter) => {
        const { city } = tc;
        acc[city] = [...(acc[city] || []), tc];
        return acc;
      },
      {}
    );
  },
  currentConsentParts(
    state: RootState
  ): (language: SupportedLanguage) => string[] {
    return (language: SupportedLanguage): string[] => {
      const { currentTestingCenterConsent } = state;
      if (!currentTestingCenterConsent) {
        return [];
      }
      const consentInLanguage = currentTestingCenterConsent[language];
      return consentInLanguage.split('\n\n');
    };
  },
};
