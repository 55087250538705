import { TestingCenter } from '@/api/models/TestingCenter';
import { ConsentResponse } from '@/api/models/TestingConsent';
import { TestingPolicy } from '@/api/models/TestingPolicy';
import { RootState, Attestation } from '@/store/models';
import { UserProfileResponse } from '@/api/models/UserProfile';
import { AppointmentDate } from '@/api/models/AppointmentDates';
import { AppointmentTime } from '@/api/models/AppointmentTimes';
import { AppErrors } from '@/api/models/Errors';

export default {
  /* API mutations */
  setTestingCenters(state: RootState, payload: TestingCenter[]): void {
    state.testingCenters = payload;
  },
  setUserProfile(state: RootState, payload?: UserProfileResponse): void {
    state.userProfile = payload;
  },
  setLabConsent(state: RootState, consent?: ConsentResponse): void {
    state.currentTestingCenterConsent = consent;
  },
  setTestingPolicy(state: RootState, policy?: TestingPolicy): void {
    state.testingPolicy = policy;
  },
  /* Internal mutations */
  setQualification(state: RootState, isQualified: boolean): void {
    state.isQualified = isQualified;
  },
  setCurrentTestingCenter(state: RootState, center?: TestingCenter): void {
    state.currentTestingCenter = center;
  },
  setIsLoading(state: RootState, isLoading: boolean): void {
    state.isLoading = isLoading;
  },
  setAppointmentDates(
    state: RootState,
    appointmentDate: AppointmentDate[]
  ): void {
    state.appointmentDates = appointmentDate;
  },
  setCurrentAppointmentDate(state: RootState, date?: AppointmentDate): void {
    state.currentAppointmentDate = date;
  },
  setAppointmentTimes(
    state: RootState,
    appointmentTime: AppointmentTime[]
  ): void {
    state.appointmentTimes = appointmentTime;
  },
  setCurrentAppointmentTime(state: RootState, time?: AppointmentTime): void {
    state.currentAppointmentTime = time;
  },
  setActiveModal(state: RootState, modalId: string): void {
    state.activeModal = modalId;
  },
  setLocale(state: RootState, locale: string): void {
    state.locale = locale;
  },
  setAttestation(state: RootState, attestation: Attestation): void {
    state.attestation = attestation;
  },
  setErrors(state: RootState, errors: Partial<AppErrors>): void {
    state.errors = {
      ...state.errors,
      ...errors,
    };
  },
};
