import Vue from 'vue';
import { i18n } from '@/i18n';
import VueGtag from 'vue-gtag';
import AppConfig from '@/config';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { AuthPlugin } from '@/plugins/auth';
import './assets/styles/index.scss';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

Vue.config.productionTip = false;

Vue.use(AuthPlugin);

Vue.use(
  VueGtag,
  {
    config: { id: AppConfig.analyticsId },
  },
  router
);

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
