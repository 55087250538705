import { format } from 'date-fns';
import { enUS, es } from 'date-fns/locale';

const locales = {
  en: enUS,
  es,
};

type AllowedLocale = 'en' | 'es';

/**
 * A wrapper around date-fns that localizes the formatted date.
 * @param date The date to format
 * @param formatString The date-fns format string
 * @param locale Either 'en' or 'es' to localize the date
 */
export const formatDate = (
  date: Date | number,
  formatString: string,
  locale: AllowedLocale = 'en'
): string => {
  console.log(date);
  return format(date, formatString, { locale: locales[locale] });
};

/**
 * Since calling new Date('12/12/1990') creates a Date object at midnight UTC,
 * then subtracts the offset, we end up with a date that's one day behind.
 *
 * To fix that, we normalize and eliminate the offset on dates with no time information.
 * @param {string} mmddyyy A string in mm-dd-yyyy or mm/dd/yyyy format
 * @returns {Date} A Date object with the correct day
 */
export const mmddyyyyToDate = (mmddyyy: string): Date => {
  const dayBehind = new Date(mmddyyy);
  return new Date(
    dayBehind.getTime() + Math.abs(dayBehind.getTimezoneOffset() * 60000)
  );
};

/**
 *
 * @param {string} date returned from the attestation endpoint. Thedate will
 *        have the fromat yyyy-mm-dd
 * @returns {string} returns a string in the format mm/dd/yyyy
 */
export const formatAttestationDate = (date: string): string => {
  const list = date.split('-');
  return list.length === 3 ? `${list[1]}/${list[2]}/${list[0]}` : '';
};
