/**
 * A single place to keep all of the API urls
 */
export const URLS = {
  ATTESTATION: `${process.env.VUE_APP_API_BASE_URL}/attestation`,
  TESTING_CENTERS: `${process.env.VUE_APP_API_BASE_URL}/testing-centers`,
  TESTING_POLICY: `${process.env.VUE_APP_API_BASE_URL}/testing-policy`,
  PROFILE: `${process.env.VUE_APP_API_BASE_URL}/profile`,
  CONSENT_BY_ID: (id: string): string =>
    `${process.env.VUE_APP_API_BASE_URL}/testing-centers/lab/${id}/consent`,
  AVAILABILITY_BY_ID: (id: string): string =>
    `${process.env.VUE_APP_API_BASE_URL}/testing-centers/${id}/availability`,
  AVAILABILITY_BY_ID_AND_DATE: (id: string, date: string): string =>
    `${process.env.VUE_APP_API_BASE_URL}/testing-centers/${id}/availability/${date}`,
};
