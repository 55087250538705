<template>
  <div class="home">
    <Home />
  </div>
</template>

<script>
import Vue from 'vue';
// @ is an alias to /src
import Home from '@/components/Home/Home.vue';

export default Vue.extend({
  name: 'HomePage',
  components: {
    Home,
  },
});
</script>
