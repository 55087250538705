



































































































































































import Vue from 'vue';
import { mapState } from 'vuex';
import { formatDate } from '@/helpers/dates';

import Card from '@/components/Card.vue';
// import CardApps from '@/components/CardApps.vue';
import Banner from '@/components/Home/Banner.vue';
import Modal from '@/components/Modal.vue';

export default Vue.extend({
  name: 'Home',
  components: { Banner, Card, /* CardApps,*/ Modal },
  computed: {
    ...mapState(['attestation', 'isLoading', 'userProfile', 'locale']),
    registrationTime() {
      const titleTemplate = this.$t('RegistrationDateFormat') as string;
      return titleTemplate.replace(
        '%@',
        formatDate(
          new Date(this.userProfile.appointments[0].created),
          'LL/dd/yy',
          this.locale
        )
      );
    },
    mapsLink: function () {
      const address =
        this.userProfile.appointments[0].testingCenter.address || '';
      const city = this.userProfile.appointments[0].testingCenter.city || '';
      const state = this.userProfile.appointments[0].testingCenter.state || '';
      const zip = this.userProfile.appointments[0].testingCenter.zipcode || '';
      return `http://maps.google.com/maps?q=${address}+${city}+${state}+${zip}`;
    },
  },
  methods: {
    formatDate,
    signIn: function () {
      this.$auth.federatedSignIn();
    },
  },
});
