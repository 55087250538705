import { Route, NavigationGuardNext } from 'vue-router';

// Routes may have dependencies on more than on feature so this accepts a single string
// or array of string in the meta for a route.
export const routeEnabledGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext
): void => {
  let isNotActive = false;

  // meta.gaurdedBy is an array of, or single, boolean that are defined in config.feature from @/config
  // A route is not accessible if at least one feature is false (disabled).
  if (to.meta && typeof to.meta.guardedBy !== 'undefined') {
    const guardedBys = Array.isArray(to.meta.guardedBy)
      ? to.meta.guardedBy
      : [to.meta.guardedBy];
    isNotActive = guardedBys.includes(false);
  }

  // Make sure we are not in an endless loop of trying to go to the 404Page
  if (isNotActive && to.name !== '404Page') {
    next({ name: '404Page' });
  } else {
    next();
  }
};
