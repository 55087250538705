// generated by googleSheetsLocalization.js (https://github.com/willowtreeapps/GoogleSheetsLocalization)
export const ENGLISH_TRANSLATIONS = {
  text: {
    TroublesomeString: '"&lt;\'Test &amp;\nString\'&gt;"',
    Cancel: 'Cancel',
    TriageHubHeader: 'UVA COVID-19 Hub',
    TriageSubtext: 'Please complete your Health Check form.',
    TriageSubtextDone:
      'You’ve already completed your Health Check today. Come back tomorrow to do it again.',
    TriageAssessment: 'Begin COVID-19 Assessment',
    TriageNotice:
      'This app is not a substitute for professional medical advice, diagnosis, or treatment. Always consult a medical professional for serious symptoms or emergencies.\n\nThis app will help summarize your symptoms and risks for Health Care Practitioners. The app is based on the latest guidelines from the United States Centers for Disease Control and Prevention (CDC).',
    Continue: 'Continue',
    ConsentDisclosureTitle: 'Consent Disclosure',
    AgreeButtonText: 'I Agree',
    DeclineButtonText: 'Decline',
    DoNotAgreeButtonText: 'I Do Not Agree',
    EmergencySymptomsOption: "I'm experiencing at least one of these",
    NoEmergencySymptomsOption: 'I do not have any of these',
    DeclineConsentToastMessage:
      'By declining the Consent Disclosure you’re not eligible to proceed through the assessment questions.',
    DeclineConsentTitle: 'Consent Declined',
    OK: 'OK',
    EmergencyHeader: 'Is this an emergency?',
    EmergencySubtext:
      'Stop and call 911 if you are experiencing:\nSevere chest pain or pressure\nExtreme difficulty breathing\nSevere, constant lightheadedness\nDisorientation or unresponsiveness',
    Back: 'Back',
    EmergencyCallSubtext:
      'Based on your reported symptoms, you should seek care immediately.',
    Call911Action: 'Call 911',
    YouShouldCall911: 'You Should Call 911',
    TriageQuestion:
      'Are you or anyone in your household experiencing any of these symptoms:',
    FeverOrChills: 'Fever or chills',
    DifficultyBreathing: 'Mild or moderate difficulty breathing',
    Cough: 'New or worsening cough',
    LossOfAppetite: 'Sustained loss of smell, taste, or appetite',
    VomitingOrDiarrhea: 'Vomiting or Diarrhea',
    SoreThroat: 'Sore throat',
    Aches: 'Aching through the body',
    SevereFatigue: 'Severe fatigue',
    NewHeadache: 'New headache',
    CongestionRunningNose: 'Congestion/running nose',
    NoneOfTheAbove: 'None of the above',
    Done: 'Done',
    NextSteps: 'Next Steps',
    NextStepsQualifyingSubheader:
      'Because you reported symptoms, you meet the criteria to be tested for Covid-19.',
    NextStepsNotQualifyingSubheader:
      'Based on your answers, the following is recommended:',
    RegisterForTesting: 'Register for Testing',
    CreateBadge:
      'Complete registration so the testing center has your information when you arrive.',
    FollowCDCGuidelinesHeader: 'Follow CDC Guidelines',
    FollowCDCGuidelinesSubheader:
      'Follow the CDC guidelines on how to protect yourself and others.',
    MonitorAnySymptomsHeader: 'Monitor Any Symptoms',
    MonitorAnySymptomsSubheader:
      'Keep an eye out for developing or worsening symptoms. Call 911 if you experience an emergency.',
    NoTestingRequiredHeader: 'No Testing Required at this Time',
    NoTestingRequiredSubheader:
      'Your answers do not signify the need for testing right now.',
    ArriveAtTestingHeader: 'Arrive at Testing',
    ArriveAtTestingSubheader: 'Follow Instructions',
    Register: 'Register',
    CDCGuidelines: 'View Guidelines',
    LoadingTestingCenters: 'Loading Testing Centers...',
    ErrorLoadingTestingCenters: 'Unable to Load Testing Centers',
    Retry: 'Retry',
    NetworkAlertMessage:
      'Please check your network connection and try loading the Testing Center Consent again.',
    NetworkAlertTitle: 'Network Error',
    SelectTestingCenterHeader: 'Select Testing Center',
    Unknown: 'Unknown',
    NoTestingCentersFoundMessage: 'No Testing Centers Found',
    FirstName: 'First Name',
    LastName: 'Last Name',
    DateOfBirth: 'Date of Birth',
    Sex: 'Sex',
    PhoneNumber: 'Phone Number',
    StreetAddress: 'Street Address',
    City: 'City',
    State: 'State',
    Zip: 'Zip',
    CDCQualifyingText:
      'Please stay home and follow CDC guidelines on what to do if you are sick',
    TestingCenterConsentTitle: '%@ Consent',
    TriageSubtextRegistered:
      'You’ve completed the Triage and qualify for a test at your registered testing center.',
    TriageSubtextNotQualifying:
      'You’ve completed the Triage, you do not qualify for testing at this point.',
    FirstNamePlaceholder: 'Taylor',
    LastNamePlaceholder: 'Smith',
    DateOfBirthPlaceholder: '06/20/1980',
    SexPlaceholder: 'Female',
    PhoneNumberPlaceholder: '(434) 555-5555',
    StreetAddressPlaceholder: '123 Blossom Street',
    CityPlaceholder: 'Charlottesville',
    StatePlaceholder: 'Virginia',
    ZipPlaceholder: '22901',
    Male: 'Male',
    Female: 'Female',
    MinimumCharactersError: '%@ must be at least %@ characters long.',
    ChooseADay: 'Choose a Day',
    ChooseATime: 'Choose a Time',
    Hours: 'Hours',
    TakeCareHeader: 'Take Care of Yourself',
    CDCNonQualifyingText:
      'Eat well, exercise, get plenty of sleep, and stay connected with others while maintaining social distance',
    TakeCareSubtext:
      'Eat well, exercise, get plenty of sleep, and stay connected with others while maintaining social distance',
    LabConsentLoadingError: 'Testing Center Consent Loading Error',
    LoadingLabConsent: 'Loading Testing Center Consent...',
    LabConsentTitle: 'Testing Center Consent',
    AtCapacity: 'At Capacity',
    HoursWithTime: 'Hours: %@ - %@',
    LabConsentDeclineMessage:
      'By declining the Consent Disclosure you’re not eligible to proceed through registration for this location.',
    AtCapacityMessageBody: 'This Day is at capacity.',
    LoadingDays: 'Loading Days...',
    ErrorLoadingDays: 'Error Loading Days',
    AccountCreatedInstructions:
      'Your registration information was sent to the email address you provided. Please have this information available when you arrive.',
    ArriveInstructions: 'Please be at the testing center at',
    NoDatesFound: 'No Dates Found',
    NoTimesFound: 'No Times Found',
    LoadingTimes: 'Loading Times...',
    LoadingTimesError: 'Error Loading Times',
    AtCapacityTimeMessageBody: 'This Time is at capacity.',
    Directions: 'Directions',
    DateOfBirthFormat: 'Date of Birth: %@',
    AccountCreationErrorTitle: 'Account Creation Error',
    AccountCreationErrorMessage:
      'We’ve encountered an error while trying to create your account.',
    SignIn: 'Sign In',
    LogIn: 'Log In',
    SignOut: 'Log Out',
    AccountPageTitle: 'Account',
    FrequentlyAskedQuestionsLink: 'Frequently Asked Questions',
    PolicyLink: 'Privacy Policy',
    PrivacyPolicyLink: 'Privacy Policy',
    AccountSettingsLink: 'Account',
    TestCenterRegistrationInfo: 'Test Center Registration Info',
    LoadingYourProfile: 'Loading Your Profile...',
    ViewGuidelines: 'View Guidelines',
    ViewGuidelinesBody:
      'Keep yourself and your community safe by following the current CDC Guidelines.',
    DownloadCovidwise: 'Help protect your community with the COVIDWISE app.',
    DownloadCovidwiseSubText: 'Offered by the Virginia Department of Health',
    SurveyBody: 'Help researchers understand COVID-19',
    TakeSurvey: 'Take Survey',
    GetDirections: 'Get Directions',
    RegistrationDateFormat: 'From %@ Registration',
    AvailableAgainAt: 'Available again at %@',
    SeeNextSteps: 'See Next Steps',
    Copyright:
      '© 2020 Rector and Visitors of the University of Virginia. All rights reserved.',
    PatientRights: 'Patient Rights',
    HIPPA: 'HIPAA Notice of Privacy Practices',
    NonDiscrimination: 'Non-Discrimination Notice',
    ConsumerInformation: 'Consumer Information',
    Accessibility: 'Accessibility',
    Emergency: 'Emergency',
    SIS: 'SIS',
    FOIA: 'FOIA',
    FinancialInterests: 'Financial interests',
    ReportAccessibility: 'Report Accessibility Barrier',
    SomethingWentWrong: 'Something went wrong',
    BackHome: 'Back to home',
    404: 'Looks like the page you’re looking for is no longer available',
    Close: 'Close',
    RegisterForTestingSubHeader:
      'Schedule a time to be tested at a local clinic.',
    Leave: 'Leave',
    LeavingRegistrationPrompt:
      'You haven’t completed registration yet. If you close this screen, you will lose your progress.',
    LeavingRegistrationPromptTitle: 'Leave Registration?',
    ProfileLoadingNetworkError:
      'An error occurred while trying to load your profile information. Please allow us to try to connect again.',
    PreferNotToAnswer: 'Prefer Not to Answer',
    White: 'White',
    Black: 'Black or African American',
    PacificIslander: 'Native Hawaiian or Other Pacific Islander',
    Asian: 'Asian',
    AmericanIndian: 'American Indian or Alaska Native',
    HispanicOrLatino: 'Hispanic or Latino',
    TwoOrMoreRaces: 'Two or More Races',
    Other: 'Other',
    Hispanic: 'Hispanic',
    NonHispanic: 'Non-Hispanic',
    Ethnicity: 'Ethnicity',
    SelectPlaceholder: 'Select %@',
    Home: 'Home',
    Race: 'Race',
    NotLoggedInCardTitle: 'Already Registered?',
    NotLoggedInCardMessage:
      'See your appointment details by logging into your account.',
    LogInAction: 'Log In',
    PositiveAttestationHeader: 'No Symptoms Reported',
    PositiveAttestationSubHeader: 'No further action is needed at this time.',
    NegativeAttestationHeader: 'Symptoms Reported',
    NegativeAttestationSubHeader:
      "Please follow our guidelines to ensure your and everyone else's safety.",
    RemoteAttestationHeader: 'Submission Complete',
    RemoteAttestationSubHeader:
      'Thank you for letting us know that you will be working remotely today.',
    RemoteAttestationDashboardHeader: 'Working remotely today',
    HealthCheckCompleteHeader: 'Health Check Complete',
    HealthCheckCompleted: 'Thank you for completing your Health Check.',
    YouAreLoggedIn: 'You are logged in.',
    Date: 'Date',
    AttestationNoSymptoms: 'Health Check: No Symptoms',
    HealthCheckSymptomatic: 'Health Check: Symptoms Reported',
    HealthCheckRemote: 'Health Check: Working remotely',
    ReturnToDashboardButton: 'Return to Dashboard',
    HealthCheckHeader: 'Health Check',
    HealthCheckHeaderDescription:
      'Are you experiencing any of the following new symptoms?',
    FeverSymptom: 'Fever (>100.4°F or 38°C)',
    CoughSymptom: 'Cough',
    ShortBreathSymptom: 'Shortness of breath or difficulty breathing',
    SoreThroatSymptom: 'Sore Throat',
    MuscleAcheSymptom: 'Muscle pain',
    RespiratorySymptom: 'Congestion or runny nose',
    ChillsSymptom: 'Chills',
    LossOfTasteSymptom: 'Loss of taste or smell',
    GISymptom: 'Gastrointestinal symptoms',
    HealthCheckFooter:
      'By selecting <b>“No”</b> below you also affirm that you have not been advised to self-quarantine as part of an active contact trace.',
    NoSymptomsButton: 'No, I Don’t Have Symptoms',
    SymptomsButton: 'Yes, I Have Symptoms',
    RemoteButton: 'I am working remotely today',
    BeginHealthCheck: 'Daily Health Check',
    HealthCheckDetails: 'Health Check Details',
    AppBenefitIntroduction:
      'Reporting of symptoms is no longer required. This website has been disabled.',
    AppBenefitReporting:
      'Reporting your symptom status through a quick daily health check.',
    AppBenefitReminders: 'Set reminders to make it even faster.',
    AppBenefitAccess:
      'Have easy access to what to do next if you develop symptoms of COVID-19.',
    LearnMore: 'Learn More',
    SymptomaticSubHeader: 'Based on your response, please follow these steps.',
    SymptomaticStepsCallProvider: 'Call a Medical Provider',
    Students: 'Students',
    ContactStudentHealth:
      'Contact Student Health & Wellness at {0} as they are NOT automatically notified by this application. Self-isolate at home or in your dorm room unless given other directions by a provider.',
    Employees: 'Employees',
    ContactEmployeeHealth:
      'Contact Employee Health at {0}. Self-isolate and notify your supervisor that you are unable to be on Grounds. Do not come to work unless cleared previously by employee health.',
    UVAWise: 'UVA Wise',
    StaffContactClinic:
      'Faculty, staff, and students should contact UVA Wise Clinic at {0}. Self-isolate, and if applicable, notify your supervisor that you are unable to be on campus.',
    OthersAffiliated: 'Others Affiliated with UVA',
    SymptomaticTestingOptions:
      'If you do have symptoms and want to get tested for COVID-19, contact your health care provider. If you do not have a personal provider, consider evaluation at a local urgent care center.',
    KeepSafeHeader: 'Keep the UVA Community Safe',
    StayOffGrounds:
      'If you are off Grounds, please do not come on Grounds. If you are on Grounds, if not already wearing a mask, please put one on immediately and return home or to your dorm room.',
    MaintainDistance:
      'Maintain at least 6 feet of physical distance from others.',
    WashHands: 'Frequently wash your hands or use hand sanitizer.',
    SeekMedicalAttention: 'If Necessary, Seek Emergency Medical Attention',
    EmergencyOptions:
      'If you are experiencing any of the following, call 911 or proceed to the nearest emergency room',
    TroubleBreathingSymptom: 'Trouble breathing',
    ChestPainSymptom: 'Persistent pain or pressure in the chest',
    ConfusionSymptom: 'New confusion',
    InabilityWakeSymptom: 'Inability to wake or stay awake',
    BluishSymptom: 'Bluish lips or face',
    OtherConcerningSymptoms:
      'Any other symptoms that are severe or concerning to you',
    HealthSystemMembersOnly: 'For Health System team members only:',
    WearMask: 'Wear a mask in the presence of others.',
    MaintainDistance6feet:
      'Maintain social distance (at least 6 feet of separation) from others.',
    CoverNoseAndMouth: 'Cover your nose and mouth when sneezing/coughing.',
    AvoidSharedSpaces: 'Avoid shared spaces.',
    WashHandsFrequently: 'Wash your hands frequently.',
    ActiveBystander:
      'Be an active bystander and encourage others to follow public health practices.',
    ContactManager:
      'If circumstances change, please reach out to your manager.',
  },
};
